<template>
    <Layout>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Project List</h4>
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div
                                    id="tickets-table_length"
                                    class="dataTables_length"
                                >
                                    <label
                                        class="d-inline-flex align-items-center"
                                    >
                                        Show&nbsp;
                                        <b-form-select
                                            v-model="perPage"
                                            size="sm"
                                            :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                    id="tickets-table_filter"
                                    class="dataTables_filter text-md-right"
                                >
                                    <label
                                        class="d-inline-flex align-items-center"
                                    >
                                        Search:
                                        <b-form-input
                                            v-model="filter"
                                            type="search"
                                            placeholder="Search..."
                                            class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                :busy="isBusy"
                                :items="projects"
                                :fields="fields"
                                responsive="sm"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                sort-icon-left
                                :filter-included-fields="filterOn"
                                :per-page="perPage"
                            >
                                <template #table-busy>
                                    <div class="text-center text-primary my-2">
                                        <b-spinner
                                            class="align-middle"
                                        ></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>

                                <template v-slot:cell(action)="data">
                                    <b-button
                                        variant="primary"
                                        @click="view(data)"
                                        >Details</b-button
                                    >
                                </template>
                            </b-table>
                        </div>

                        <div class="row float-right">
                            <i
                                class="ri-arrow-left-line arrow"
                                @click="previous"
                            ></i>
                            &nbsp;&nbsp;&nbsp;
                            <i
                                class="ri-arrow-right-line arrow"
                                @click="next"
                            ></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<script>
import Layout from "../../layouts/main.vue";
export default {
    components: {
        Layout,
    },
    data() {
        return {
            isBusy: true,
            totalRows: 1,
            currentPage: 1,
            perPage: 50,
            pageOptions: [5, 10, 25, 50],
            filter: null,
            filterOn: [],
            sortDesc: false,
            offset: 0,
            fields: [
                { key: "id", sortable: true },
                { key: "title", sortable: true, label: "Project" },
                { key: "description", sortable: true },
                "Action",
            ],
        };
    },
    methods: {
        view(data) {
            console.log(data.item);
            this.$router.push({
                path: `/project/${data.item.id}`,
            });
        },
        getProjectList() {
            this.$store
                .dispatch("apidata/fetchProjects", this.offset)
                .then(() => {
                    this.isBusy = false;
                });
        },
        previous() {
            if (this.offset == 0) {
                return;
            }
            this.offset = this.offset - 50;
            this.isBusy = true;
            this.getProjectList();
        },
        next() {
            this.isBusy = true;
            this.offset = this.offset + 50;
            this.getProjectList();
        },
    },
    mounted() {
        this.getProjectList();
    },

    computed: {
        projects() {
            return this.$store.getters["apidata/getProjects"];
        },
        rows() {
            return this.projectList.length;
        },
    },
};
</script>
<style scoped>
.arrow {
    font-size: 20px;
    font-weight: bolder;
}
.arrow:hover {
    text-align: center;
    cursor: pointer;
    background-color: #c59220;
    color: white;
    border-radius: 50%;
    height: 30px;
    width: 30px;
}
</style>
